import { keyBy } from 'lodash/fp';
import { DefaultLocale } from 'Translation';

export type * from './Locale.types';

type LocaleOptions = {
    supportsIME?: boolean;
    imeCharMultiplier?: (char: string) => number;
    rtl?: boolean;
};

const getSpacesNecessaryForInput =
    ({ imeCharMultiplier, supportsIME }: LocaleOptions = {}) =>
    (text: string) => {
        if (imeCharMultiplier && supportsIME) {
            return [...text].reduce((count, char) => count + imeCharMultiplier(char), 0);
        }
        return text.length;
    };

const createLocale = (code: string, title: string, options: LocaleOptions = {}) => ({
    code,
    value: code,
    title,
    spacesNecessaryForInput: getSpacesNecessaryForInput(options),
    supportsIME: false,
    imeCharMultiplier: undefined,
    rtl: false,
    ...options,
});

const locales = {
    english: createLocale('en', 'English'),
    spanish: createLocale('es', 'Español'),
    italian: createLocale('it', 'Italiano'),
    chinese: createLocale('zh', '中文', {
        supportsIME: true,
        imeCharMultiplier: (char: string) => (char.charCodeAt(0) > 255 ? 4 : 1),
    }),
    arabic: createLocale('ar', 'العربية', {
        rtl: true,
    }),
    amharic: createLocale('am', 'አማርኛ'),
} as const;

const localesByCode = keyBy('code', Object.values(locales));

const availablePrefLocales = Object.values(locales);

export const editorLocales = Object.values(locales);

const editorLocalesWithoutEnglish = editorLocales.filter(locale => locale !== locales.english);

const editorLocaleCodeWithoutEnglish = editorLocalesWithoutEnglish.map(({ code }) => code);

export const editorLocale = DefaultLocale;

export default {
    ...locales,
    ...localesByCode,
    availablePrefLocales,
    editorLocales,
    editorLocaleCodeWithoutEnglish,
    editorLocalesWithoutEnglish,
    editorLocale,
};
