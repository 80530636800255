window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.featured_events = window.locales.am.lessons.stream.featured_events || {};window.locales.am.lessons.stream.featured_events = {...window.locales.am.lessons.stream.featured_events, ...{
    "upcoming_events": "መጪ ክስተቶች",
    "featured_events": "ተለይተው የቀረቡ ክስተቶች",
    "view_featured_events": "ተለይተው የቀረቡ ክስተቶችን ይመልከቱ",
    "view_more_upcoming_events": "ተጨማሪ ክስተቶችን ይመልከቱ",
    "view_all_events": "ሁሉንም ክስተቶች ይመልከቱ",
    "view_all": "ሁሉንም ይመልከቱ",
    "multiple_times": "ብዙ ጊዜ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.featured_events = window.locales.ar.lessons.stream.featured_events || {};window.locales.ar.lessons.stream.featured_events = {...window.locales.ar.lessons.stream.featured_events, ...{
    "upcoming_events": "الأحداث القادمة",
    "featured_events": "فعاليات مميزة",
    "view_featured_events": "عرض الأحداث المميزة",
    "view_more_upcoming_events": "عرض المزيد من الأحداث",
    "view_all_events": "عرض جميع الأحداث",
    "view_all": "عرض الكل",
    "multiple_times": "مرات متعددة"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.featured_events = window.locales.en.lessons.stream.featured_events || {};window.locales.en.lessons.stream.featured_events = {...window.locales.en.lessons.stream.featured_events, ...{
    "upcoming_events": "Upcoming Events",
    "featured_events": "Featured Events",
    "view_featured_events": "View Featured Events",
    "view_more_upcoming_events": "View More Events",
    "view_all_events": "View All Events",
    "view_all": "View All",
    "multiple_times": "Multiple Times"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.featured_events = window.locales.es.lessons.stream.featured_events || {};window.locales.es.lessons.stream.featured_events = {...window.locales.es.lessons.stream.featured_events, ...{
    "upcoming_events": "Próximos Eventos",
    "featured_events": "Eventos especiales",
    "view_featured_events": "Ver eventos destacados",
    "view_more_upcoming_events": "Ver más eventos",
    "view_all_events": "Ver todos los eventos",
    "view_all": "Ver todo",
    "multiple_times": "Varias veces"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.featured_events = window.locales.it.lessons.stream.featured_events || {};window.locales.it.lessons.stream.featured_events = {...window.locales.it.lessons.stream.featured_events, ...{
    "upcoming_events": "Prossimi eventi",
    "featured_events": "Eventi in primo piano",
    "view_featured_events": "Visualizza gli eventi in primo piano",
    "view_more_upcoming_events": "Visualizza altri eventi",
    "view_all_events": "Visualizza tutti gli eventi",
    "view_all": "Visualizza tutto",
    "multiple_times": "Più volte"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.featured_events = window.locales.zh.lessons.stream.featured_events || {};window.locales.zh.lessons.stream.featured_events = {...window.locales.zh.lessons.stream.featured_events, ...{
    "upcoming_events": "即将举行的活动",
    "featured_events": "特色活动",
    "view_featured_events": "查看特色活动",
    "view_more_upcoming_events": "查看更多活动",
    "view_all_events": "查看所有活动",
    "view_all": "查看全部",
    "multiple_times": "多次"
}
};