window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.lessons = window.locales.am.lessons || {};window.locales.am.lessons.stream = window.locales.am.lessons.stream || {};window.locales.am.lessons.stream.student_dashboard = window.locales.am.lessons.stream.student_dashboard || {};window.locales.am.lessons.stream.student_dashboard = {...window.locales.am.lessons.stream.student_dashboard, ...{
    "active_playlist": "ንቁ የአጫዋች ዝርዝር",           
    "activate_playlist": "የአጫዋች ዝርዝርን ያንቁ",           
    "bookmarked_courses": "ቡክማርክ የተደረጉ ኮርሶች",           
    "bos": "Blue Ocean ስትራቴጂ",           
    "browse": "ያስሱ",           
    "browse_all": "ሁሉንም ያስሱ",           
    "browse_courses": "ሁሉንም ኮርሶች ያስሱ",           
    "browse_playlists": "ሁሉንም የአጫዋች ዝርዝሮች ያስሱ",           
    "close": "ዝጋ",           
    "completed_all_playlists": "ሁሉንም የእኛ አጫዋች ዝርዝሮችን አጠናቅቀዋል!!<br> ለአዳዲስ ኮርሶች እና አጫዋች ዝርዝሮች ይከታተሉ።",           
    "completed_courses_title": "የተጠናቀቁ ኮርሶች",           
    "dashboard_title": "ዳሽቦርድ",           
    "discover_bos": "ፈጠራን የሚሸልመው እና ጎን ለጎን ውድድርን የሚያዘጋጀው የ Blue Ocean ስትራቴጂን ለማግኘት ከስር ያለው ኮርስ ላይ ጠቅ ያድርጉ።",           
    "free_to_share": "ይህንን ገጽ ሲያገኝ ለሚያስደስተው ሰው እንዲሁ ለማጋራት ነጻ ይሁኑ!",           
    "go_courses": "ወደ ኮርሶች ይሂዱ",           
    "go_my_courses": "ወደ እኔ ኮርሶች ይሂዱ",           
    "happy_learning": "መልካም ትምህርት!",           
    "keep_learning": "መማርዎን ይቀጥሉ",           
    "courses": "ኮርሶች",           
    "no_bookmarked_courses": "ቡክማርክ የተደረጉ ኮርሶች የሉዎትም።",           
    "tourtip_bos_saved": "እነዚህ የእርስዎ የተቀመጡ ኮርሶች ናቸው። የ Blue Ocean ስትራቴጂ ኮርስን ለእርስዎ አክለናል።",           
    "my_courses": "የእኔ ኮርሶች",           
    "my_courses_title": "የእኔ ኮርሶች",           
    "nice_work": "አሪፍ ስራ!",           
    "no_thanks": "አይ እናመሰግናለን",           
    "not_sure_browse_playlists": "እርግጠኛ አይደሉም? <a ng-click=\"openPlaylistLibrary()\">ሁሉንም የአጫዋች ዝርዝሮች ያስሱ</a>",           
    "playlist": "የአጫዋች ዝርዝር",           
    "playlists": "የአጫዋች ዝርዝሮች",           
    "settings": "ቅንብሮች",           
    "share_email": "በኢሜይል ያጋሩ",           
    "share_facebook": "በፌስቡክ ያጋሩ",           
    "share_linkedin": "በሊንክደኢን ያጋሩ",           
    "share_twitter": "በትዊተር ያጋሩ",           
    "start_learning": "መማር ይጀምሩ",           
    "start_tour": "ጉዞ ይጀምሩ",           
    "recommend_x_playlist_x_progress": "የ <strong>{{title}}</strong> አጫዋች ዝርዝርን እንመክራለን። እርስዎ <strong>የኮርሶቹን {{progress}}% </strong> አስቀድመው አጠናቅቀዋል፣ ስለዚህ በደንብ መንገድዎን ይዘዋል ማለት ነው!",           
    "recommend_x_playlist_0_progress": "የ <strong>{{title}}</strong> አጫዋች ዝርዝርን እንመክራለን።",           
    "thank_you": "እናመሰግናለን፣ እና መልካም የትምህርት ጊዜ!",           
    "tourtip_bookmark": "የታሰረበትን ክር ነካ በማድረግ አንድ ኮርስን ማከል ወይም ማስወገድ ይችላሉ።",           
    "tourtip_complete": "ጉዞ ያጠናቅቁ",           
    "tourtip_dashboard": "ይህ የእርስዎ ዳሽቦርድ ነው። የእርስዎ የተቀመጡ ኮርሶች እዚህ ጋር ይገኛሉ።",           
    "tourtip_next": "ቀጣይ",           
    "tourtip_recommend": "ይህ ቀጣይ የሚመከር ኮርስ የሚያገኙበት ስፍራ ነው። አንድ ኮርስ ሲጨርሱ፣ አዲስ ኮርስ እንዲጀምሩ እንጠቁምዎታለን።",           
    "tourtip_saved": "እነዚህ የእርስዎ የተቀመጡ ኮርሶች ናቸው። እርስዎ እንዲጀምሩ ለማድረግ አንዳንድ ኮርሶችን አክለናል።",           
    "tourtip_playlist": "ይህ የእርስዎ የኮርሶች አጫዋች ዝርዝር ነው። እርስዎ እንዲጀምሩ ለማድረግ የ MBA መሰረታዊ ነገሮች አጫዋች ዝርዝርን አንቅተናል።",           
    "tourtip_playlist_institutions": "ይህ የእርስዎ የኮርሶች አጫዋች ዝርዝር ነው። እርስዎ እንዲጀምሩ ለማድረግ የአጫዋች ዝርዝርን አንቅተናል።",           
    "tourtip_saved_subscriptions": "እነዚህ የእርስዎ የተቀመጡ ኮርሶች ናቸው። እርስዎ እንዲጀምሩ ለማድረግ ጥቂት ኮርሶችን አክለናል።",           
    "valued_beta_tester": "ዋጋ እንዳለው የቤታ ሞካሪ ሰው፣ ሰብስክራይብ ለማድረግ እንኳን ቢወስኑም ለእርስዎ የ MBA የዝግጅት ኮርሶች ያልተገደበ መዳረሻን ይይዛሉ።",           
    "view_playlist": "የአጫዋች ዝርዝርን ይመልከቱ",           
    "you_have_completed_all_of_your_courses": "የእርስዎን ሁሉንም ኮርሶች አጠናቅቀዋል።",           
    "welcome_to_your_dashbaord": "ወደራስዎ ዳሽቦርድ እንኳን በደህና መጡ!",           
    "try_any_free_lessons": "ከእርስዎ <a ng-click=\"closeModal()\">ዳሽቦርድ</a> ከነጻ ትምህርቶቹ የትኛውንም ይሞክሩ።",           
    "complete_your_application": "ዝግጁ ሲሆኑ፣ <a ng-click=\"completeApplication()\">ማመልከቻዎትን ያጠናቅቁ</a>።",           
    "need_more_info": "ተጨማሪ መረጃ ይፈልጋሉ? <a ng-click=\"learnMore()\">ስለፕሮግራሞቻችን የበለጠ ይወቁ</a>።",           
    "home_title": "{{institutionName}}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.lessons = window.locales.ar.lessons || {};window.locales.ar.lessons.stream = window.locales.ar.lessons.stream || {};window.locales.ar.lessons.stream.student_dashboard = window.locales.ar.lessons.stream.student_dashboard || {};window.locales.ar.lessons.stream.student_dashboard = {...window.locales.ar.lessons.stream.student_dashboard, ...{
    "active_playlist": "قائمة التشغيل النشطة",           
    "activate_playlist": "قائمة التشغيل النشطة",           
    "bookmarked_courses": "الدورات ذات الإشارات المرجعية",           
    "browse": "تصفح",           
    "browse_all": "تصفح الكل",           
    "browse_playlists": "تصفح قوائم التشغيل",           
    "close": "غلق",           
    "completed_all_playlists": "لقد أكملت جميع قوائم التشغيل الخاصة بنا!<br>ترقب الدورات وقوائم التشغيل الجديدة.",           
    "completed_courses_title": "الدورات المكتملة",           
    "dashboard_title": "لوحة القيادة",           
    "free_to_share": "لا تتردد في مشاركة هذه الصفحة مع الشخص الذي تعتقد أنه سوف يستمتع بها، أيضاً!",           
    "happy_learning": "نتمنى لك تعلم سعيد!",           
    "keep_learning": "واصل التعلم",           
    "no_bookmarked_courses": "لا يوجد لديك دورات ذات إشارات مرجعية.",           
    "my_courses": "دوراتي",           
    "my_courses_title": "دوراتي",           
    "nice_work": "عمل جيد!",           
    "no_thanks": "لا شكراً لك",           
    "not_sure_browse_playlists": "هل أنت غير متأكد؟ <a ng-click=\"openPlaylistLibrary()\">تصفح جميع قوائم التشغيل</a>",           
    "playlist": "قائمة التشغيل",           
    "playlists": "قوائم التشغيل",           
    "settings": "الإعدادات",           
    "share_email": "قُم بالمشاركة بواسطة البريد الإلكتروني",           
    "share_facebook": "قُم بالمشاركة بواسطة فيس بوك",           
    "share_linkedin": "قُم بالمشاركة بواسطة LinkedIn",           
    "share_twitter": "قُم بالمشاركة بواسطة تويتر",           
    "start_learning": "ابدأ في التعلم",           
    "start_tour": "ابدأ الجولة",           
    "recommend_x_playlist_x_progress": "إننا ننصح بـ <strong>{{title}}</strong> قائمة التشغيل. لقد قمت <strong>بإكمال {{progress}}% </strong> من الدورات بالفعل، لذلك فإنك تحرز تقدماً على طريق نجاحك!",           
    "recommend_x_playlist_0_progress": "إننا ننصح بـ <strong>{{title}}</strong> قائمة التشغيل",           
    "thank_you": "شكراً لك، ونتمنى لك تعلم سعيد!",           
    "tourtip_bookmark": "يمكنك إضافة أو إزالة دورة ما من خلال النقر على شريط الدورة.",           
    "tourtip_complete": "جولة كاملة",           
    "tourtip_dashboard": "هذه هي لوحة التحكم الخاصة بك. دوراتك التي تم حفظها متاحة هنا.",           
    "tourtip_next": "التالى",           
    "tourtip_recommend": "هذا هو المكان الذي سوف تجد فيه دورتك المقبلة الموصى بها. عندما تقوم بإنهاء الدورة، فإننا سوف نقترح دورة جديدة من أجلك لتبدأ في دراستها.",           
    "tourtip_saved": "هذه هي الدورات التي تم حفظها. لقد قمنا بإضافة بعض الدورات لتبدأ في دراستها.",           
    "tourtip_playlist": "هذه هي قائمة التشغيل الخاصة بك للدورات. لقد قمنا بتنشيط قائمة التشغيل الخاصة بأساسيات MBA لتبدأ في دراستها.",           
    "tourtip_playlist_institutions": "هذه هي قائمة التشغيل الخاصة بك للدورات. لقد قمنا بتنشيط قائمة التشغيل لتبدأ في دراستها.",           
    "tourtip_saved_subscriptions": "هذه هي الدورات التي تم حفظها. لقد قمنا بإضافة عدد قليل من الدورات لتبدأ في دراستها.",           
    "valued_beta_tester": "لأنك بمثابة مجرّب محترم للإصدار التجريبي، فإنك سوف تحتفظ بإمكانية الدخول غير المحدود إلى الدورات الإعدادية لـ MBA، حتى إذا قررت عدم الاشتراك.",           
    "view_playlist": "عرض قائمة التشغيل",           
    "you_have_completed_all_of_your_courses": "لقد أكملت جميع دوراتك!",           
    "bos": "استراتيجية المحيط الأزرق",
    "discover_bos": "انقر على الدورة أدناه لاكتشاف Blue Ocean Strategy ، وهي استراتيجية جريئة للأنشطة التجارية تكافئ الابتكار وتطرح المنافسة جانباً.",
    "tourtip_bos_saved": "هذه هي الدورات التدريبية المحفوظة الخاصة بك. لقد أضفنا دورة استراتيجية المحيط الأزرق من أجلك.",
    "browse_courses": "دورات التصفح",
    "go_courses": "انتقل إلى الدورات",
    "go_my_courses": "انتقل إلى الدورات الخاصة بي",
    "courses": "الدورات",
    "home_title": "{{institutionName}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.lessons = window.locales.en.lessons || {};window.locales.en.lessons.stream = window.locales.en.lessons.stream || {};window.locales.en.lessons.stream.student_dashboard = window.locales.en.lessons.stream.student_dashboard || {};window.locales.en.lessons.stream.student_dashboard = {...window.locales.en.lessons.stream.student_dashboard, ...{
    "active_playlist": "ACTIVE PLAYLIST",
    "activate_playlist": "ACTIVATE PLAYLIST",
    "bookmarked_courses": "BOOKMARKED COURSES",
    "bos": "Blue Ocean Strategy",
    "browse": "BROWSE",
    "browse_all": "BROWSE ALL",
    "browse_courses": "BROWSE Courses",
    "browse_playlists": "BROWSE PLAYLISTS",
    "close": "CLOSE",
    "completed_all_playlists": "You've completed all of our playlists!<br>Stay tuned for new courses and playlists.",
    "completed_courses_title": "Completed Courses",
    "dashboard_title": "DASHBOARD",
    "discover_bos": "Click the course below to discover Blue Ocean Strategy, a bold business strategy that rewards innovation and casts competition aside.",
    "free_to_share": "Feel free to share this page with someone you think would enjoy it, too!",
    "go_courses": "GO TO COURSES",
    "go_my_courses": "GO TO MY COURSES",
    "happy_learning": "Happy Learning!",
    "home_title": "{{institutionName}}",
    "keep_learning": "KEEP LEARNING",
    "courses": "COURSES",
    "no_bookmarked_courses": "You have no bookmarked courses.",
    "tourtip_bos_saved": "These are your saved courses. We've added the Blue Ocean Strategy course for you.",
    "my_courses": "MY COURSES",
    "my_courses_title": "MY COURSES",
    "nice_work": "Nice Work!",
    "no_thanks": "No thanks",
    "not_sure_browse_playlists": "Not sure? <a ng-click=\"openPlaylistLibrary()\">Browse all playlists</a>",
    "playlist": "PLAYLIST",
    "playlists": "PLAYLISTS",
    "settings": "SETTINGS",
    "share_email": "Share by Email",
    "share_facebook": "Share on Facebook",
    "share_linkedin": "Share on LinkedIn",
    "share_twitter": "Share on Twitter",
    "start_learning": "START LEARNING",
    "start_tour": "START TOUR",
    "recommend_x_playlist_x_progress": "We recommend the <strong>{{title}}</strong> playlist. You've <strong>already completed {{progress}}% </strong> of its courses, so you're well on your way!",
    "recommend_x_playlist_0_progress": "We recommend the <strong>{{title}}</strong> playlist.",
    "thank_you": "Thank you, and happy learning!",
    "tourtip_bookmark": "You can add or remove a course by tapping its ribbon.",
    "tourtip_complete": "COMPLETE TOUR",
    "tourtip_dashboard": "This is your dashboard. Your saved courses are available here.",
    "tourtip_next": "Next",
    "tourtip_recommend": "This is where you'll find your next recommended course. When you finish a course, we'll suggest a new one for you to start.",
    "tourtip_saved": "These are your saved courses. We've added some courses to get you started.",
    "tourtip_playlist": "This is your playlist of courses. We've activated the MBA Fundamentals playlist to get you started.",
    "tourtip_playlist_institutions": "This is your playlist of courses. We've activated a playlist to get you started.",
    "tourtip_saved_subscriptions": "These are your saved courses. We've added a few courses to get you started.",
    "valued_beta_tester": "As a valued beta tester, you will retain unlimited access to your MBA Prep courses, even if you decide not to subscribe.",
    "view_playlist": "VIEW PLAYLIST",
    "you_have_completed_all_of_your_courses": "You’ve completed all of your courses!"
}
};window.locales.es = window.locales.es || {};window.locales.es.lessons = window.locales.es.lessons || {};window.locales.es.lessons.stream = window.locales.es.lessons.stream || {};window.locales.es.lessons.stream.student_dashboard = window.locales.es.lessons.stream.student_dashboard || {};window.locales.es.lessons.stream.student_dashboard = {...window.locales.es.lessons.stream.student_dashboard, ...{
    "browse_all": "EXPLORAR TODOS",           
    "close": "CERRAR",           
    "completed_courses_title": "Cursos completados",           
    "dashboard_title": "PANEL DE CONTROL",           
    "free_to_share": "¡No dudes en compartir esta página con alguien que piensa que le gustaría, también!",           
    "happy_learning": "¡Feliz aprendizaje!",           
    "my_courses": "MIS CURSOS",           
    "my_courses_title": "Mis cursos",           
    "no_thanks": "No, gracias",           
    "settings": "AJUSTES",           
    "share_email": "Compartir por correo electrónico",           
    "share_facebook": "Compartir en Facebook",           
    "share_linkedin": "Compartir en LinkedIn",           
    "share_twitter": "Compartir en Twitter",           
    "start_tour": "COMENZAR RECORRIDO",           
    "thank_you": "Gracias, y ¡feliz aprendizaje!",           
    "tourtip_bookmark": "Puedes agregar o quitar un curso tocando la cinta.",           
    "tourtip_complete": "RECORRIDO COMPLETO",           
    "tourtip_dashboard": "Este es tu panel de control. Tus cursos guardados están disponibles aquí.",           
    "tourtip_next": "Siguiente",           
    "tourtip_recommend": "Aquí es donde encontrarás tu próximo curso recomendado. Cuando termines el curso, te sugeriremos que comiences uno nuevo.",           
    "tourtip_saved": "Estos son tus cursos guardados. Hemos añadido algunos cursos para que los empieces.",           
    "tourtip_saved_subscriptions": "Estos son tus cursos guardados. Hemos añadido un par de cursos para ayudarte a empezar.",           
    "valued_beta_tester": "Como valorado probador beta, conservarás el acceso ilimitado a tus cursos MBA Prep, incluso si decides no suscribirse.",           
    "active_playlist": "PLAYLIST ACTIVA",           
    "activate_playlist": "ACTIVAR PLAYLIST",           
    "bookmarked_courses": "CURSOS MARCADOS",           
    "browse": "EXPLORAR",           
    "browse_playlists": "EXPLORAR PLAYLISTS",           
    "keep_learning": "SIGUE APRENDIENDO",           
    "nice_work": "¡Buen trabajo!",           
    "playlists": "PLAYLISTS",           
    "recommend_x_playlist": "Recomendamos CORE playlist MBA. Usted ya ha completado el 18% de sus cursos, por lo que está bien en su camino!",
    "not_sure_browse_playlists": "¿No estás seguro? <a ng-click=\"openPlaylistLibrary()\">Explora todas las playlists</a>",           
    "recommend_x_playlist_0_progress": "Recomendamos la playlist<strong>{{title}}</strong>.",           
    "tourtip_playlist": "Esta es tu playlist de cursos. Hemos activado la playlist de Fundamentos de MBA para ayudarte a empezar.",           
    "tourtip_playlist_institutions": "Esta es tu playlist de cursos. Hemos activado una playlist para ayudarte a empezar.",           
    "view_playlist": "VER PLAYLIST",           
    "you_have_completed_all_of_your_courses": "¡Has completado todos los cursos!",           
    "completed_all_playlists": "¡Has completado todas nuestras playlists!<br>Permanece atento a nuevos cursos y playlists.",           
    "no_bookmarked_courses": "No tienes cursos marcados.",           
    "playlist": "PLAYLIST",           
    "start_learning": "COMIENZA A APRENDER",           
    "recommend_x_playlist_x_progress": "Recomendamos la playlist<strong>{{title}}</strong>.<strong>Ya has completado un {{progress}} % </strong> de sus cursos, ¡así que vas por buen camino!",           
    "bos": "Estrategia de océano azul",
    "discover_bos": "Haga clic en el curso a continuación para descubrir Blue Ocean Strategy, una estrategia de negocios audaz que premia la innovación y descarta la competencia.",
    "tourtip_bos_saved": "Estos son tus cursos guardados. Hemos agregado el curso Blue Ocean Strategy para ti.",
    "browse_courses": "VER Cursos",
    "go_courses": "IR A CURSOS",
    "go_my_courses": "IR A MIS CURSOS",
    "courses": "CURSOS",
    "home_title": "{{institutionName}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.lessons = window.locales.it.lessons || {};window.locales.it.lessons.stream = window.locales.it.lessons.stream || {};window.locales.it.lessons.stream.student_dashboard = window.locales.it.lessons.stream.student_dashboard || {};window.locales.it.lessons.stream.student_dashboard = {...window.locales.it.lessons.stream.student_dashboard, ...{
    "active_playlist": "PLAYLIST ATTIVA",           
    "activate_playlist": "ATTIVA PLAYLIST",           
    "bookmarked_courses": "CORSI PREFERITI",           
    "browse": "SFOGLIA",           
    "browse_all": "SFOGLIA TUTTO",           
    "browse_playlists": "SFOGLIA PLAYLIST",           
    "close": "CHIUDI",           
    "completed_all_playlists": "Hai completato tutte le nostre playlist!<br>Resta sintonizzato per nuovi corsi e playlist.",           
    "completed_courses_title": "Corsi completati",           
    "dashboard_title": "BACHECA",           
    "free_to_share": "Se ti va, condividi questa pagina con qualcuno a cui pensi piacerebbe!",           
    "happy_learning": "Buon Apprendimento!",           
    "keep_learning": "CONTINUA A IMPARARE",           
    "no_bookmarked_courses": "Non hai corsi preferiti.",           
    "my_courses": "I MIEI CORSI",           
    "my_courses_title": "I MIEI CORSI",           
    "nice_work": "Ottimo lavoro!",           
    "no_thanks": "No, grazie",           
    "not_sure_browse_playlists": "Indeciso? <a ng-click=\"openPlaylistLibrary()\">Sfoglia tutte le playlist</a>",           
    "playlist": "PLAYLIST",           
    "playlists": "PLAYLIST\n",           
    "settings": "IMPOSTAZIONI",           
    "share_email": "Condividi via email",           
    "share_facebook": "Condividi su Facebook",           
    "share_linkedin": "Condividi su LinkedIn",           
    "share_twitter": "Condividi su Twitter",           
    "start_learning": "COMINCIA A IMPARARE",           
    "start_tour": "COMINCIA IL TOUR",           
    "recommend_x_playlist_x_progress": "Consigliamo la playlist <strong>{{title}}</strong>. Hai <strong>già completato il {{progress}}% </strong> dei suoi corsi, quindi sei a buon punto!",           
    "recommend_x_playlist_0_progress": "Consigliamo la playlist <strong>{{title}}</strong>.",           
    "thank_you": "Grazie, e buon apprendimento!",           
    "tourtip_bookmark": "Puoi aggiungere o rimuovere un corso toccando sul suo nastro.",           
    "tourtip_complete": "COMPLETA IL TOUR",           
    "tourtip_dashboard": "Questa è la tua bacheca. I corsi che hai salvato sono disponibili qui.",           
    "tourtip_next": "Avanti",           
    "tourtip_recommend": "Qui troverai il tuo prossimo corso consigliato. Quando finirai un corso, te ne suggeriremo uno nuovo da iniziare.",           
    "tourtip_saved": "Questi sono i corsi che hai salvato. Abbiamo aggiunto alcuni corsi per cominciare.",           
    "tourtip_playlist": "Questa è la tua playlist di corsi. Abbiamo attivato la playlist Fondamenti MBA per cominciare.",           
    "tourtip_playlist_institutions": "Questa è la tua playlist di corsi. Abbiamo attivato una playlist per cominciare.",           
    "tourtip_saved_subscriptions": "Questi sono i corsi che hai salvato. Abbiamo aggiunto qualche corso per cominciare.",           
    "valued_beta_tester": "Come prezioso beta tester, conserverai l'accesso illimitato ai nostri corsi di Preparazione MBA anche se deciderai di non iscriverti.",           
    "view_playlist": "VISUALIZZA PLAYLIST",           
    "you_have_completed_all_of_your_courses": "Hai completato tutti i tuoi corsi!",           
    "bos": "Strategia dell'oceano blu",
    "discover_bos": "Fai clic sul corso sottostante per scoprire Blue Ocean Strategy, una strategia aziendale audace che premia l'innovazione e mette da parte la competizione.",
    "tourtip_bos_saved": "Questi sono i tuoi corsi salvati. Abbiamo aggiunto il corso Blue Ocean Strategy per te.",
    "browse_courses": "SFOGLIA Corsi",
    "go_courses": "VAI AI CORSI",
    "go_my_courses": "VAI AI MIEI CORSI",
    "courses": "CORSI",
    "home_title": "{{institutionName}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.lessons = window.locales.zh.lessons || {};window.locales.zh.lessons.stream = window.locales.zh.lessons.stream || {};window.locales.zh.lessons.stream.student_dashboard = window.locales.zh.lessons.stream.student_dashboard || {};window.locales.zh.lessons.stream.student_dashboard = {...window.locales.zh.lessons.stream.student_dashboard, ...{
    "active_playlist": "有效任务列表",           
    "activate_playlist": "激活任务列表",           
    "bookmarked_courses": "已收藏课程",           
    "browse": "浏览",           
    "browse_all": "浏览全部",           
    "browse_playlists": "浏览任务列表",           
    "close": "关闭",           
    "completed_all_playlists": "您已完成我们的所有任务列表！<br>请静待新的课程和任务列表。",           
    "completed_courses_title": "已完成课程",           
    "dashboard_title": "控制面板",           
    "free_to_share": "请将此页面与您觉得也会喜欢的人随意分享！",           
    "happy_learning": "学习快乐！",           
    "keep_learning": "继续学习",           
    "no_bookmarked_courses": "您没有收藏的课程。",           
    "my_courses": "我的课程",           
    "my_courses_title": "我的课程",           
    "nice_work": "做得很好！",           
    "no_thanks": "不，谢谢",           
    "not_sure_browse_playlists": "不确定？<a ng-click=\"openPlaylistLibrary()\">浏览所有任务列表</a>",           
    "playlist": "任务列表",           
    "playlists": "任务列表",           
    "settings": "设置",           
    "share_email": "通过电子邮件分享",           
    "share_facebook": "在 Facebook 上分享",           
    "share_linkedin": "在 LinkedIn 上分享",           
    "share_twitter": "在 Twitter 上分享",           
    "start_learning": "开始学习",           
    "start_tour": "开始浏览",           
    "recommend_x_playlist_x_progress": "我们推荐 <strong>{{title}}</strong> 任务列表。您对其课程<strong>已经完成了 {{progress}}%</strong>，因此进展顺利！",           
    "recommend_x_playlist_0_progress": "我们推荐 <strong>{{title}}</strong> 任务列表。",           
    "thank_you": "谢谢，学习快乐！",           
    "tourtip_bookmark": "您可以通过点按其功能区添加或删除课程。",           
    "tourtip_complete": "完成浏览",           
    "tourtip_dashboard": "这是您的控制面板。您保存的课程在这儿提供。",           
    "tourtip_next": "下一个",           
    "tourtip_recommend": "这是可以找到您的下一个推荐课程的地方。在您完成课程时，我们将建议您开始学习新课程。",           
    "tourtip_saved": "这些是您保存的课程。我们添加了一些课程供您开始学习。",           
    "tourtip_playlist": "这是您的课程任务列表。我们激活了 MBA Fundamentals 激动列表供您开始学习。",           
    "tourtip_playlist_institutions": "这是您的课程任务列表。我们激活了一个激动列表供您开始学习。",           
    "tourtip_saved_subscriptions": "这些是您保存的课程。我们添加了一些课程供您开始学习。",           
    "valued_beta_tester": "作为我们重视的测试版测试用户，即使您决定不订阅，您也将保有对您的 MBA Prep 课程的无限访问权限。",           
    "view_playlist": "查看任务列表",           
    "you_have_completed_all_of_your_courses": "您已完成了您的所有课程！",           
    "bos": "蓝海战略",           
    "discover_bos": "点击下面的课程，探索蓝海战略，这是一项奖励创新、摒弃竞争的大胆商业战略。",           
    "tourtip_bos_saved": "你已保存这些课程。我们为你添加了蓝海战略课程。",           
    "browse_courses": "浏览课程",           
    "go_courses": "前往课程",           
    "go_my_courses": "前往我的课程",           
    "courses": "课程",           
    "home_title": "{{institutionName}}"
}
};