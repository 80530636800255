import { getErrorBoundary, type ErrorHandlingOptions, defaultErrorHandlingComponents } from 'FrontRoyalErrorBoundary';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { memo, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

/*
    LoadingBoundary combines an ErrorBoundary and a SuspenseBoundary. It can be used whenever we want to
    block a particular part of the UI from rendering until everything within it is ready.
    if errorActions are not provided, it will use defaultErrorHandlingComponents.
*/
export const LoadingBoundary = memo(
    ({
        children,
        errorActions = defaultErrorHandlingComponents,
    }: {
        errorActions?: ErrorHandlingOptions;
        children: React.ReactNode;
    }) => (
        <ErrorBoundary FallbackComponent={getErrorBoundary(errorActions)}>
            <Suspense fallback={<FrontRoyalSpinner />}>{children}</Suspense>
        </ErrorBoundary>
    ),
);
